<template>
    <b-modal
        id="ModalUpdateContact"
        size="md"
        title="Modifier un contact"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        hide-footer
    >
        <FormContact :contact="contact" @saved="onSaved" />
    </b-modal>
</template>
<script>
import FormContact from "@/components/model/contact/FormContact";
export default {
    components: {
        FormContact,
    },

    props: {
        contact: { type: Object, required: true },
    },

    methods: {
        onSaved() {
            this.$bvModal.hide("ModalUpdateContact");
            this.$emit("saved");
        },
    },
};
</script>
