<template>
    <div class="k-flex-col">
        <div>
            <b-input-group>
                <template #prepend>
                    <b-input-group-text>
                        <b-icon-search />
                    </b-input-group-text>
                </template>
                <b-form-input
                    placeholder="Rechercher un projet"
                    v-model="filter"
                    debounce="500"
                />
                <template #append>
                    <b-button variant="secondary" @click="filter = ''">
                        Effacer
                    </b-button>
                </template>
            </b-input-group>
        </div>
        <b-table
            class="text-break mt-2"
            :items="dataProvider"
            :fields="fields"
            :current-page="currentPage"
            :filter="filter"
            show-empty
            empty-text="Aucun projet"
            empty-filtered-text="Aucun projet trouvé"
            stacked="md"
            hover
            tbody-tr-class="pointer"
            @row-clicked="goProject"
            striped
        >
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Chargement des données...</strong>
                </div>
            </template>
        </b-table>
        <div>
            <b-pagination
                class="m-auto"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                align="center"
                size="sm"
                first-number
                last-number
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        perPage: { type: String, default: "8" },
        contactId: [String, Number],
    },

    data: function() {
        return {
            totalRows: 0,
            currentPage: 1,
            fields: [
                {
                    key: "name",
                    label: "Projet",
                    sortable: true,
                    tdClass: "text-kalkin-1",
                },
                {
                    key: "description",
                    label: "Description",
                },
            ],
            filter: "",
        };
    },

    watch: {
        async filter() {
            await this.fetchTotalRows();
        },
    },

    methods: {
        async fetchTotalRows() {
            this.totalRows = await this.$store.dispatch(
                "contact/countFSPContactProjects",
                {
                    contactId: this.contactId,
                    filter: `name substring ${this.filter}`,
                },
            );
        },

        async dataProvider(ctx) {
            return await this.$store.dispatch("contact/getFSPContactProjects", {
                contactId: this.contactId,
                filter: `name substring ${this.filter}`,
                sortby: (ctx.sortDesc ? "-" : "") + ctx.sortBy,
                limit: this.perPage,
                offset: this.perPage * (this.currentPage - 1),
            });
        },

        goProject(item) {
            this.$router.push({
                name: "AdminProject",
                params: { projectId: item.id },
            });
        },
    },

    async beforeMount() {
        await this.fetchTotalRows();
    },
};
</script>

<style></style>
