<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h2>
                Contact
                <small class="text-kalkin-2">
                    <b-icon-card-heading /> {{ contact.name }}
                </small>
            </h2>
            <div>
                <b-button
                    class="mr-2"
                    size="sm"
                    variant="outline-secondary"
                    v-b-modal.ModalUpdateContact
                >
                    <b-icon-pencil /> Modifier le contact
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-danger"
                    v-b-modal.ModalDanger
                >
                    <b-icon-trash /> Supprimer le contact
                </b-button>
            </div>
        </div>

        <div class="k-page-body text-left">
            <div class="k-panel-2">
                <h3>Informations</h3>
                <div>
                    Rôle :
                    <span class="text-kalkin-2">{{
                        contact.role || "Non renseigné"
                    }}</span>
                </div>
                <div>
                    Adresse :
                    <span class="text-kalkin-2">{{
                        contact.address || "Non renseigné"
                    }}</span>
                </div>
                <div>
                    Email :
                    <span class="text-kalkin-2">{{
                        contact.mail || "Non renseigné"
                    }}</span>
                </div>
                <div>
                    Téléphone :
                    <span class="text-kalkin-2">
                        {{ contact.phone || "Non renseigné" }}
                    </span>
                </div>
                <div>
                    Ajouté le
                    <span class="text-kalkin-2">
                        {{ contact.createdAt | dateFromISO }}
                    </span>
                </div>
            </div>

            <div class="k-panel-2">
                <h3>Projets du contact</h3>
                <TableContactProjects :contact-id="contactId" />
            </div>
        </div>

        <ModalUpdateContact :contact="contact" @saved="fetchContact" />
        <ModalDanger
            message="Voulez-vous vraiment supprimer ce contact?"
            @confirmed="deleteContact"
        />
    </div>
</template>
<script>
import ModalUpdateContact from "@/components/model/contact/ModalUpdateContact";
import ModalDanger from "@/components/modal/ModalDanger";
import TableContactProjects from "@/components/model/contact/TableContactProjects";
export default {
    components: {
        ModalUpdateContact,
        TableContactProjects,
        ModalDanger,
    },

    data: function() {
        return {
            contactId: this.$route.params.contactId,
            contact: {},
        };
    },

    methods: {
        async fetchContact() {
            this.contact = await this.$store.dispatch("contact/getContact", {
                id: this.contactId,
            });
            if (!this.contact) {
                this.$router.push({ name: "NotFound" });
            }
        },

        async deleteContact() {
            await this.$store.dispatch("contact/deleteContact", {
                contactId: this.contact.id,
            });
            this.$router.push({ name: "AdminListContacts" });
        },
    },

    async beforeMount() {
        await this.fetchContact();
    },
};
</script>
